export function setDonors(state, data) {
  console.log('store.donors.mutations setDonors()', data);
  state.donors.data = data.data ?? [];
  state.donors.meta = data.meta ?? {};
}

export function setCurrentDonor(state, data) {
  console.log('store.donors.mutations setCurrentDonor()', data);
  state.currentDonor = data;
}
