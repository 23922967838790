<template>
  <div class="alert alert-danger" role="alert">
    <h4 class="alert-heading">Error</h4>
    <p>{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: [Error, Boolean],
      required: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    errorString: {
      type: [String, Boolean],
      required: false,
    },
  },
  computed: {
    errorMessage() {
      if (this.errorString) {
        return this.errorString;
      }

      if (typeof this.error !== 'object') {
        return 'Unknown Error';
      }

      if (this.error.response) {
        if (Object.prototype.hasOwnProperty.call(this.error.response, 'data')) {
          if (Object.prototype.hasOwnProperty.call(this.error.response.data, 'errors')) {
            const { errors } = this.error.response.data;
            return errors[Object.keys(errors)[0]];
          }
          if (Object.prototype.hasOwnProperty.call(this.error.response.data, 'message')) {
            if (this.error.response.data.message.indexOf('timeout') !== -1) {
              return 'The application server is not responding. Please contact technical support.';
            }
            return this.error.response.data.message;
          }
        }
      }

      return Object.prototype.hasOwnProperty.call(this.error, 'message')
        ? this.cleanMessage(this.error.message)
        : 'Unknown Error';
    },
    errorType() {
      return typeof this.error;
    },
    response() {
      return this.error.response;
    },
  },
  methods: {
    cleanMessage(message) {
      return message.replace(/[[\]"]/g, '');
    },
  },
};
</script>

<style lang="scss"></style>
