export function setCampaigns(state, data) {
  // console.log('store.campaigns.mutations setCampaigns()', data);
  state.campaigns.data = data.data ?? [];
  state.campaigns.meta = data.meta ?? {};
}

export function setCurrentCampaign(state, data) {
  // console.log('store.campaigns.mutations setCurrentCampaign()', data);
  state.currentCampaign = data;
}

export function setCurrentCampaigns(state, data) {
  // console.log('store.campaigns.mutations setCurrentCampaigns()', data);
  state.currentCampaigns = data;
}
