import { axiosInstance as axios, queryParams } from '../../plugins/axios';

export async function getAll(context, payload) {
  const { params } = payload;
  // console.log('store.campaigns.actions.getAll() params', params);
  const config = {
    method: 'get',
    url: `campaigns${queryParams(params)}`,
  };

  // console.info('store.campaigns.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.getAll() then', response);
      context.commit('setCampaigns', response.data);

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function get(context, payload) {
  const { id, params } = payload;
  // console.log('store.campaigns.actions.get() :id', id);
  const config = {
    method: 'get',
    url: `campaigns/${id}${queryParams(params)}`,
  };

  // console.info('store.campaigns.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.get() then', response);
      context.commit('setCurrentCampaign', response.data.data);
      /*if (window.location.href.indexOf('campaigns') !== -1) {
        context.commit('ui/setPageTitle', response.data.data.title, { root: true });
      }*/

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  // console.log('store.campaigns.actions.create() :payload', payload);
  const { formData } = payload;
  const config = {
    method: 'post',
    url: 'campaigns',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.campaigns.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.create() then', response);
      // context.dispatch('getAll', {});

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, payload) {
  // console.log('store.campaigns.actions.update() :id :payload', payload);
  const { formData, options } = payload;
  const config = {
    method: options.method,
    url: `campaigns/${options.id}`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.campaigns.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.update() then', response);
      // context.commit('setCurrentCampaign', response.data.data);
      /*if (window.location.href.indexOf('campaigns') !== -1) {
        context.commit('ui/setPageTitle', response.data.data.title, { root: true });
      }*/
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  // console.log('store.campaigns.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `campaigns/${id}`,
  };

  // console.info('store.campaigns.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.destroy() then', response);

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getCurrent(context, payload) {
  const { params } = payload;
  // console.log('store.campaigns.actions.getCurrent()');
  const config = {
    method: 'get',
    url: `campaigns/now${queryParams(params)}`,
  };

  // console.info('store.campaigns.actions.getCurrent() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.getCurrent() then', response);
      context.commit('setCurrentCampaigns', response.data.data);
      /*if (window.location.href.indexOf('campaigns') !== -1) {
        context.commit('ui/setPageTitle', response.data.data.title, { root: true });
      }*/

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.getCurrent() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getDonations(context, payload) {
  // console.log('store.campaigns.actions.getDonations() :payload', payload);
  const { id, params, saveToStore } = payload;
  const config = {
    method: 'get',
    url: `campaigns/${id}/donations${queryParams(params)}`,
  };

  // console.info('store.campaigns.actions.getDonations() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.getDonations() then', response);
      if (saveToStore) {
        context.commit('donations/setDonations', response.data, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.getDonations() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getPromotions(context, payload) {
  // console.log('store.campaigns.actions.getPromotions() :payload', payload);
  const { id, params, saveToStore } = payload;
  const config = {
    method: 'get',
    url: `campaigns/${id}/promotions${queryParams(params)}`,
  };

  // console.info('store.campaigns.actions.getPromotions() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.getPromotions() then', response);
      if (saveToStore) {
        context.commit('promotions/setPromotions', response.data, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.getPromotions() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getPledges(context, payload) {
  // console.log('store.campaigns.actions.getPledges() :payload', payload);
  const { id, params, saveToStore } = payload;
  const config = {
    method: 'get',
    url: `campaigns/${id}/pledges${queryParams(params)}`,
  };

  // console.info('store.campaigns.actions.getPledges() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.campaigns.actions.getPledges() then', response);
      if (saveToStore) {
        context.commit('pledges/setPledges', response.data, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.getPledges() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function updateLogo(context, payload) {
  console.log('store.campaigns.actions.updateLogo() :payload', payload);
  const { id, formData } = payload;
  const config = {
    method: 'post',
    url: `campaigns/${id}/logo`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  console.info('store.campaigns.actions.updateLogo() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.campaigns.actions.updateLogo() then', response);
      return response;
    })
    .catch((error) => {
      console.error('store.campaigns.actions.updateLogo() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
