export function openMenu(context) {
  context.commit('setMenuState', true);
}

export function closeMenu(context) {
  context.commit('setMenuState', false);
}

export function toggleMenu(context) {
  context.commit('setMenuState', !context.state.menuOpen);
}

export function disableRefresh(context) {
  context.commit('disableRefresh', !context.state.dashboardRefresh);
}

export function setRefreshRate(context, rate) {
  context.commit('setRefreshRate', rate);
}
