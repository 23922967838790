import { axiosInstance as axios } from '../../plugins/axios';
import * as _ from 'lodash';

// eslint-disable-next-line no-unused-vars
export function all(context) {
  // console.log('store.settings.actions.all() :params', params);
  const config = {
    method: 'get',
    url: 'settings',
  };

  // console.info('store.settings.actions.all() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.settings.actions.all() then', response);

      return response;
    })
    .catch((error) => {
      // console.error('store.settings.actions.all() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export function get(context, payload) {
  // console.log('store.settings.actions.get() :payload', payload);
  const { id } = payload;
  const config = {
    method: 'get',
    url: `settings/${id}`,
  };

  // console.info('store.settings.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.settings.actions.get() then', response);
      const { data } = response.data;
      const settingName = _.camelCase(data.label);
      context.commit('set', { setting: settingName, data });

      return response;
    })
    .catch((error) => {
      // console.error('store.settings.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export function store(context, payload) {
  // console.log('store.settings.actions.store() :payload', payload);
  const { data } = payload;
  const config = {
    method: 'post',
    url: `settings`,
    data,
  };

  console.info('store.settings.act/ions.store() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.settings.actions.store() then', response);
      return response;
    })
    .catch((error) => {
      // console.error('store.settings.actions.store() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export function update(context, payload) {
  // console.log('store.settings.actions.update() :payload', payload);
  const { id, data } = payload;
  data._method = 'PUT';
  const config = {
    method: 'put',
    url: `settings/${id}`,
    data,
  };

  // console.info('store.settings.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.settings.actions.update() then', response);
      return response;
    })
    .catch((error) => {
      // console.error('store.settings.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
