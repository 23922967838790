export function loginFailure(state) {
  console.log('store.auth.mutations.loginFailure', state);
}

export function loginSuccess(state) {
  console.log('store.auth.mutations.loginSuccess', state);
}

export function logout(state) {
  console.log('store.auth.mutations.loginSuccess', state);
}

export function setToken(state, { token, expires, rememberMe }) {
  // console.log('store.auth.mutations.setToken', { token, expires, rememberMe });
  state.token = token;
  state.expires = expires;
  state.rememberMe = rememberMe;

  // Save the token into local storage
  localStorage.setItem('access_token', [token, expires.toISOString()].join('|'));
  localStorage.setItem('remember', rememberMe);
}

export function setRefreshTimer(state, { timer }) {
  // console.log('store.auth.mutations.setRefreshTimer', timer);
  // If there is an existing timer, cancel it first so it doesn't run unintended
  if (state.refreshTokenTimer !== null) {
    // console.log('store.auth.mutations.setRefreshTimer clearTimeout');
    clearTimeout(state.refreshTokenTimer);
  }
  state.refreshTokenTimer = timer;
}

export function destroyToken(state) {
  // console.log('store.auth.mutations.destroyToken');
  state.token = null;
  state.expires = null;
  state.rememberMe = false;
  state.refreshTokenTimer = null;
}
