export function setRoles(state, data) {
  // console.log('store.roles.mutations setRoles()', data);
  state.roles.data = data.data ?? [];
  state.roles.meta = data.meta ?? {};
}

export function setCurrentRole(state, data) {
  // console.log('store.roles.mutations setCurrentRole()', data);
  state.currentRole = data;
}
