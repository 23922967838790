import { axiosInstance as axios, queryParams } from '../../plugins/axios';
import * as _ from 'lodash';

export async function getAll(context, payload) {
  const { params, saveToStore, refreshFavorites } = payload;
  // console.log('store.donations.actions.getAll()');
  const config = {
    method: 'get',
    url: `donations${queryParams(params)}`,
  };

  // console.info('store.donations.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.donations.actions.getAll() then', response);
      if (saveToStore) {
        context.commit('setDonations', response.data);
      }

      // Refresh Favs whenever we get a bunch of donations
      if (refreshFavorites) {
        context.dispatch('favoriteComments/getAll', {}, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.donations.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function get(context, payload) {
  const { id, params } = payload;
  // console.log('store.donations.actions.get() :id', { id });
  let url = `donations/${id}`;
  if (Object.keys(params).length) {
    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    url = `donations/${id}?${query}`;
  }
  const config = {
    method: 'get',
    url,
  };

  // console.info('store.donations.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.donations.actions.get() then', response);
      context.commit('setCurrentDonation', response.data.data);
      if (window.location.href.indexOf('donations') !== -1) {
        const title = response.data.data.display_as
          ? response.data.data.display_as
          : [response.data.data.first, response.data.data.last].join(' ');
        context.commit('ui/setPageTitle', title, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.donations.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  // console.log('store.donations.actions.create() :payload', { payload });
  const config = {
    method: 'post',
    url: 'donations',
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.donations.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.donations.actions.create() then', response);
      context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.donations.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, { id, payload }) {
  // console.log('store.donations.actions.update() :id :payload', { id, payload });
  const config = {
    method: 'put',
    url: `donations/${id}`,
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.donations.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.donations.actions.update() then', response);
      context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.donations.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  // console.log('store.donations.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `donations/${id}`,
  };

  // console.info('store.donations.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.donations.actions.destroy() then', response);

      return response;
    })
    .catch((error) => {
      console.error('store.donations.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function restore(context, { id }) {
  // console.log('store.donations.actions.restore() :id', id);
  const config = {
    method: 'post',
    url: `donations/${id}/restore`,
  };

  // console.info('store.donations.actions.restore() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.donations.actions.restore() then', response);

      return response;
    })
    .catch((error) => {
      console.error('store.donations.actions.restore() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
