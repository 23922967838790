// export async function get(context, payload) {
//   console.log('store.search.actions.get() :payload', payload);
// }

export async function set(context, payload) {
  // console.log('store.search.actions.set() :payload', payload);

  if (Object.prototype.hasOwnProperty.call('keywords', payload)) {
    const { keywords } = payload;
    context.commit('setKeywords', keywords);
  }

  if (Object.prototype.hasOwnProperty.call('range', payload)) {
    const { range } = payload;
    context.commit('setRange', range);
  }
}

export async function setKeywords(context, value) {
  // console.log('store.search.actions.setKeywords() :value', value);

  context.commit('setKeywords', value);
}

export async function setRange(context, value) {
  // console.log('store.search.actions.setRange() :value', value);

  context.commit('setRange', value);
}

export async function destroy(context, param) {
  // console.log('store.search.actions.destroy() :param', param);

  if (param === 'keywords') {
    context.commit('clearKeywords');
  }

  if (param === 'range') {
    context.commit('clearRange');
  }
}
