export function setUsers(state, data) {
  // console.log('store.users.mutations setUsers()', data);
  state.users.data = data.data ?? [];
  state.users.meta = data.meta ?? {};
}

export function setCurrentUser(state, data) {
  console.log('store.users.mutations setCurrentUser()', data);
  state.currentUser = data;
}

export function setActiveUser(state, data) {
  // console.log('store.users.mutations setActiveUser()', data);
  state.activeUser = data;
}
