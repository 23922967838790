import store from '../store';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Authenticated" */ '../layouts/Authenticated'),
    beforeEnter: (to, from, next) => {
      // console.log('routes.js beforeEnter() Authenticated', { to, from, next });
      if (
        Object.prototype.hasOwnProperty.call(to, 'meta') &&
        Object.prototype.hasOwnProperty.call(to.meta, 'title')
      ) {
        // console.log('routes.js beforeEnter() document.title', to.meta.title);
        document.title = `WGTS Donations | ${to.meta.title}`;
      } else {
        document.title = 'WGTS Donations';
      }

      // Check for Authentication
      if (store.getters['auth/loggedIn']) {
        // If authenticated, continue
        next();
      } else {
        // redirect if not authenticated
        next({ name: 'login' });
      }
    },
    children: [
      {
        path: '',
        redirect: { name: 'dashboard' },
        meta: {
          needsAuth: true,
        },
      },
      /**
       * Dashboard
       */
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          title: 'Dashboard',
        },
        component: () => import('../pages/Dashboard.vue'),
      },
      /**
       * Core paths for Campaigns
       */
      {
        path: 'campaigns',
        name: 'campaigns',
        meta: {
          title: 'Campaigns',
        },
        component: () => import('../pages/CampaignsIndex.vue'),
      },
      {
        path: 'campaigns/:id',
        name: 'campaign',
        meta: {
          title: 'Campaign',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);
          let openCreatePromotion = route.query.ocp || false;
          props.openCreatePromotion = openCreatePromotion;
          return props;
        },
        component: () => import('../pages/CampaignsShow.vue'),
      },
      {
        path: 'campaigns/:id/donations',
        name: 'campaignDonations',
        meta: {
          title: 'Campaign :id Donations',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/CampaignDonationsIndex.vue'),
      },
      {
        path: 'campaigns/:id/pledges',
        name: 'campaignPledges',
        meta: {
          title: 'Campaign :id Pledges',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/CampaignPledgesIndex.vue'),
      },
      {
        path: 'campaigns/:id/promotions',
        name: 'campaignPromotions',
        meta: {
          title: 'Campaign :id Promotions',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/CampaignPromotionsIndex.vue'),
      },
      /**
       * Core paths for Donations
       */
      {
        path: 'donations',
        name: 'donations',
        meta: {
          title: 'Donations',
        },
        component: () => import('../pages/DonationsIndex.vue'),
      },
      {
        path: 'donations/:id',
        name: 'donation',
        meta: {
          title: 'Donation',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/DonationsShow.vue'),
      },
      /**
       * Core paths for Donors
       */
      {
        path: 'donors',
        name: 'donors',
        meta: {
          title: 'Donors',
        },
        component: () => import('../pages/DonorsIndex.vue'),
      },
      {
        path: 'donors/:id',
        name: 'donor',
        meta: {
          title: 'Donor',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/DonorsShow.vue'),
      },
      /**
       * Core paths for FavoriteComments
       */
      {
        path: 'favorite-comments',
        name: 'favoriteComments',
        meta: {
          title: 'Your Favorite Comments',
        },
        component: () => import('../pages/FavoriteCommentsIndex.vue'),
      },
      /**
       * Core paths for GiveAways
       */
      {
        path: 'giveaways',
        name: 'giveaways',
        meta: {
          title: 'Giveaways',
        },
        component: () => import('../pages/GiveawaysIndex.vue'),
      },
      {
        path: 'giveaways/:id',
        name: 'giveaway',
        meta: {
          title: 'Giveaway',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/GiveawaysShow.vue'),
      },
      /**
       * Core paths for Goals
       */
      {
        path: 'goals',
        name: 'goals',
        meta: {
          title: 'Goals',
        },
        component: () => import('../pages/GoalsIndex.vue'),
      },
      {
        path: 'goals/:id',
        name: 'goal',
        meta: {
          title: 'Goal',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/GoalsShow.vue'),
      },
      /**
       * Core paths for Matches
       */
      {
        path: 'matches',
        name: 'matches',
        meta: {
          title: 'Matches',
        },
        component: () => import('../pages/MatchesIndex.vue'),
      },
      {
        path: 'matches/:id',
        name: 'match',
        meta: {
          title: 'Match',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/MatchesShow.vue'),
      },
      /**
       * Core paths for Pledges
       */
      {
        path: 'pledges',
        name: 'pledges',
        meta: {
          title: 'Pledges',
        },
        component: () => import('../pages/PledgesIndex.vue'),
      },
      {
        path: 'pledges/:id',
        name: 'pledge',
        meta: {
          title: 'Pledge',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/PledgesShow.vue'),
      },
      /**
       * Core paths for Promotions
       */
      {
        path: 'promotions',
        name: 'promotions',
        meta: {
          title: 'Promotions',
        },
        component: () => import('../pages/PromotionsIndex.vue'),
      },
      {
        path: 'promotions/:id',
        name: 'promotion',
        meta: {
          title: 'Promotion',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);
          let showEditForm = route.query.sef || false;
          props.showEditForm = showEditForm;
          return props;
        },
        component: () => import('../pages/PromotionsShow.vue'),
      },
      /**
       * Core paths for Roles
       */
      {
        path: 'roles',
        name: 'roles',
        meta: {
          title: 'Roles',
        },
        component: () => import('../pages/RolesIndex.vue'),
      },
      {
        path: 'roles/:id',
        name: 'role',
        meta: {
          title: 'Role',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/RolesShow.vue'),
      },
      /**
       * Core paths for Users
       */
      {
        path: 'users',
        name: 'users',
        meta: {
          title: 'Users',
        },
        component: () => import('../pages/UsersIndex.vue'),
      },
      {
        path: 'users/:id',
        name: 'user',
        meta: {
          title: 'User',
        },
        props(route) {
          const props = { ...route.params };
          props.id = Number(props.id);

          return props;
        },
        component: () => import('../pages/UsersShow.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        meta: {
          title: 'My Profile',
        },
        component: () => import('../pages/MyProfile.vue'),
      },
      /**
       * Settings
       */
      {
        path: 'settings',
        name: 'settings',
        meta: {
          title: 'Site Settings',
        },
        component: () => import('../pages/Settings'),
      },
    ],
  },

  {
    path: '/presentation',
    name: 'presentation',
    component: () =>
      import(
        /* webpackChunkName: "AuthenticatedPresentation" */ '../layouts/AuthenticatedPresentation'
      ),
    beforeEnter: (to, from, next) => {
      // console.log('routes.js beforeEnter() AuthenticatedPresentation', { to, from, next });

      if (
        Object.prototype.hasOwnProperty.call(to, 'meta') &&
        Object.prototype.hasOwnProperty.call(to.meta, 'title')
      ) {
        // console.log('routes.js beforeEnter() document.title', to.meta.title);
        document.title = `WGTS Donations | ${to.meta.title}`;
      } else {
        document.title = 'WGTS Donations';
      }

      // Check for Authentication
      if (store.getters['auth/loggedIn']) {
        // If authenticated, continue
        next();
      } else {
        // redirect if not authenticated
        next({ name: 'login' });
      }
    },
  },

  /**
   * Authentication Paths for login/logout, forgot
   */
  {
    path: '/login',
    redirect: { name: 'login' },
  },
  {
    path: '/logout',
    redirect: { name: 'logout' },
  },
  {
    path: '/auth',
    component: () => import('../layouts/UnAuthenticatedLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Login',
        },
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        path: 'logout',
        name: 'logout',
        meta: {
          title: 'Logout',
        },
        component: () => import('../pages/auth/Logout.vue'),
      },
      {
        path: 'forgot-username',
        name: 'forgotUsername',
        meta: {
          title: 'Forgot Username',
        },
        component: () => import('../pages/auth/ForgotPassword.vue'),
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        meta: {
          title: 'Forgot Password',
        },
        component: () => import('../pages/auth/ForgotUsername.vue'),
      },
    ],
  },

  /**
   * Catch-all for 404 Not Found
   */
  {
    path: '/:pathMatch(.*)*',
    meta: {
      title: 'Error 404',
    },
    component: () => import('../pages/errors/404.vue'),
  },
];

export default routes;
