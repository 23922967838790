import { axiosInstance as axios, queryParams } from '../../plugins/axios';

export async function getAll(context, payload) {
  const { params } = payload;
  // console.log('store.users.actions.getAll()');
  const config = {
    method: 'get',
    url: `users${queryParams(params)}`,
  };

  // console.info('store.users.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.getAll() then', response);
      context.commit('setUsers', response.data);

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function get(context, { id }) {
  // console.log('store.users.actions.get() :id', { id });
  const config = {
    method: 'get',
    url: `users/${id}`,
  };

  // console.info('store.users.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.get() then', response);
      context.commit('setCurrentUser', response.data.data);
      if (window.location.href.indexOf('users') !== -1) {
        const title = [response.data.data.first, response.data.data.last].join(' ');
        context.commit('ui/setPageTitle', title, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getMe(context) {
  // console.log('store.users.actions.getMe()');
  const config = {
    method: 'get',
    url: 'me',
  };

  // console.info('store.users.actions.getMe() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.getMe() then', response);
      context.commit('setActiveUser', response.data.data);
      if (window.location.href.indexOf('users') !== -1) {
        const title = [response.data.data.first, response.data.data.last].join(' ');
        context.commit('ui/setPageTitle', title, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.getMe() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  // console.log('store.users.actions.create() :payload', { payload });
  const { formData, options } = payload;
  const config = {
    method: options.method,
    url: 'users',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.users.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.create() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, payload) {
  // console.log('store.users.actions.update() :id :payload', payload);
  const { formData, options } = payload;
  const config = {
    method: options.method,
    url: `users/${options.id}`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.users.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.update() then', response);
      // context.commit('setCurrentUser', response.data.data);
      /*if (window.location.href.indexOf('users') !== -1) {
        const title = [response.data.data.first, response.data.data.last].join(' ');
        context.commit('ui/setPageTitle', title, { root: true });
      }*/

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function addRoles(context, payload) {
  // console.log('store.users.actions.addRoles() :payload', payload);
  const { formData, options } = payload;
  const { id, method } = options;
  const config = {
    method: method || 'POST',
    url: `users/${id}/roles`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.users.actions.addRoles() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.addRoles() then', response);
      context.dispatch('get', { id });

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.addRoles() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function removeRoles(context, payload) {
  // console.log('store.users.actions.removeRoles() :payload', payload);
  const { options } = payload;
  const { user_id, role_id, method } = options;
  const config = {
    method: method || 'DELETE',
    url: `users/${user_id}/roles/${role_id}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.users.actions.removeRoles() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.removeRoles() then', response);
      context.dispatch('get', { id: user_id });

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.removeRoles() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  console.log('store.users.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `users/${id}`,
  };

  // console.info('store.users.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.users.actions.destroy() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.users.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
