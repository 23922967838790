export function getAll(state) {
  // console.log('store.users.getters.getAll()', state.users);
  return state.users.data;
}

export function getAllMeta(state) {
  // console.log('store.users.getters.getAll()', state.users);
  return state.users.meta;
}

export function getCurrentUser(state) {
  // console.log('store.users.getters.getCurrentUser()', state);
  return state.currentUser;
}

export function getActiveUser(state) {
  // console.log('store.users.getters.getActiveUser()', state);
  return state.activeUser;
}
