import dayjs from 'dayjs';

export function setKeywords(state, data) {
  // console.log('store.search.mutations.setKeywords()', data);
  state.keywords = data ?? '';
}

export function setRange(state, data) {
  // console.log('store.search.mutations.setRange()', data);
  state.range = {
    start: dayjs(data.starts_at || data.start || '').toDate(),
    end: dayjs(data.ends_at || data.end || '').toDate(),
  };
}

export function clearSearch(state) {
  // console.log('store.search.mutations.resetState()');
  state.keywords = '';
  state.range = {
    start: '',
    end: '',
  };
}

export function clearKeywords(state) {
  // console.log('store.search.mutations.clearKeywords()');
  state.keywords = '';
}

export function clearRange(state) {
  // console.log('store.search.mutations.clearRange()');
  state.range = {
    start: '',
    end: '',
  };
}
