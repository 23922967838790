export function setPageTitle(state, data) {
  state.pageTitle = data;
}

export function setMenuState(state, value) {
  state.menuOpen = value;
}

export function disableRefresh(state, value) {
  state.dashboardRefresh = value;
}

export function setRefreshRate(state, value) {
  console.log('store.ui.mutations.setRefreshRate :rate', value);
  state.dashboardRefreshInterval = value;
}
