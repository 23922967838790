import { axiosInstance as axios } from '../../plugins/axios';
import dayjs from 'dayjs';
import * as _ from 'lodash';

export function login({ dispatch }, payload) {
  // console.log('store.auth.actions.login()');
  return dispatch('getToken', payload);
}

export function logout({ dispatch }) {
  // console.log('store.auth.actions.logout()');
  // Call the API to tell it to remove the token
  return dispatch('destroyToken');
}

export function getToken({ dispatch }, { email, password, rememberMe }) {
  const data = new URLSearchParams(); // FormData();

  // attach to FormData
  data.append('email', email);
  data.append('password', password);

  const config = {
    method: 'post',
    url: '/authenticate',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.auth.actions.getToken() before', config);

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.auth.actions.getToken() then', response);
      const { expires_in, access_token } = response.data;
      // Set expires to be an instance of `moment`
      const expires = dayjs().add(expires_in, 'second');

      // Save the token
      dispatch('saveToken', { token: access_token, expires, rememberMe });

      // Get this User's info
      dispatch('users/getMe', null, { root: true });

      return response;
    })
    .catch((error) => {
      console.error('store.auth.actions.getToken() error', error);
      // #TODO Handle general network errors
      dispatch('destroyToken');

      throw error;
    });
}

export function getAuthenticationStatus(context) {
  // Do we have a token?
  if (context.state.token === null) {
    // console.log('store.auth.actions.getAuthenticationStatus() token is null');
    // Token is empty, see if we have it in a cookie
    // If we don't have token, assume we are coming from a refresh
    if (localStorage.getItem('access_token')) {
      // console.log('store.auth.actions.getAuthenticationStatus() access_token exists');
      const currentTime = dayjs(); // new Date().getTime() / 1000;
      const expires = dayjs(context.rootGetters['auth/getExpires']);
      const authenticated = currentTime.isBefore(expires);

      // console.log('store.auth.actions.getAuthenticationStatus() authenticationStatus', {
      //   currentTime: currentTime.format(),
      //   expires: expires.format(),
      //   authenticated,
      // });
      return authenticated; // currentTime.isBefore(expires);
    }
    // No session Cookie
    console.info('store.auth.actions.getAuthenticationStatus() access_token not exists');
  }

  return false;
}

export function refreshToken({ dispatch, getters }) {
  // console.info('store.auth.actions.refreshToken()');
  const config = {
    method: 'get',
    url: '/refresh',
  };

  // console.info('store.auth.actions.refreshToken() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.auth.actions.refreshToken() then', response);
      const { expires_in, access_token } = response.data;
      // Set expires to be an instance of `moment`
      const expires = dayjs().add(expires_in, 'second');

      // Save the token
      dispatch('saveToken', { token: access_token, expires, rememberMe: getters['getRememberMe'] });

      return access_token;
    })
    .catch((error) => {
      console.error('store.auth.actions.refreshToken() error', error);
      // #TODO Handle general network errors
      dispatch('destroyToken');

      throw error;
    });
}

/**
 * Save the JWT token to state, localstorage, axios, and initiate refreshToken
 * @param {Object} context
 * @param context.commit
 * @param context.dispatch
 * @param {Object} payload
 * @param {string} payload.token
 * @param {moment} payload.expires
 * @param payload.rememberMe
 */
export function saveToken({ commit, dispatch }, { token, expires, rememberMe }) {
  // console.log('store.auth.actions.saveToken()', { token, expires, rememberMe });
  if (rememberMe) {
    // Set the timer that will renew the token 5 minutes before it expires
    const refreshTime = expires.clone().subtract(15, 'minute');
    const timeout = refreshTime.diff(dayjs());
    // console.info('store.auth.actions.saveToken() timeout', timeout);
    const refreshTimer = setTimeout(function () {
      dispatch('refreshToken');
    }, timeout);

    commit('setRefreshTimer', { timer: refreshTimer });
    // console.log('store.auth.actions.saveToken() setRefreshTimer', refreshTimer);
  }

  // Save the token into state
  commit('setToken', { token, expires, rememberMe });

  // Save the token into Axios
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

export async function destroyToken({ commit, state }) {
  // console.log('store.auth.actions.destroyToken()');
  // Destroy the token in local storage
  localStorage.removeItem('access_token');
  localStorage.removeItem('remember');

  // Destroy the token in state
  const { token } = state;
  // If there is no token in state, then just exit with a resolved Promise
  if (_.isEmpty(token)) {
    return Promise.resolve();
  }
  // Otherwise tell the API to destroy the token
  const config = {
    method: 'get',
    url: '/logout',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  // console.info('store.auth.actions.destroyToken() before', config);

  return axios
    .request(config)
    .then((response) => {
      // console.info('store.auth.actions.destroyToken() then', response);
      return response;
    })
    .catch((error) => {
      // console.error('store.auth.actions.destroyToken() error', error);
      // #TODO Handle general network errors
      // Handle a 401 here which is to be expected.
      if (error.response && error.response.status === 401) {
        console.info('store.auth.actions.destroyToken() error, got expected 401');
        // Do nothing here, this is expected
      } else {
        throw error;
      }
    })
    .finally(() => {
      // Always remove from state
      commit('destroyToken');
    });
}
