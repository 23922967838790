import dayjs from 'dayjs';

const access_token = localStorage.getItem('access_token')
  ? localStorage.getItem('access_token').split('|')
  : null;
const rememberMe = localStorage.getItem('remember')
  ? localStorage.getItem('remember').toLowerCase() === 'true'
  : false;

let token = null;
let expires = null;

if (access_token !== null) {
  token = access_token[0];
  expires = dayjs(access_token[1]);
}

// console.log('auth state.js', { token, expires });

export default {
  token: token || null,
  expires: expires || null,
  rememberMe,
  refreshTokenTimer: null,
};
