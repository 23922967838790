export function setPledges(state, data) {
  // console.log('store.pledges.mutations setPledges()', data);
  state.pledges.data = data.data ?? [];
  state.pledges.meta = data.meta ?? {};
}

export function setCurrentPledge(state, data) {
  // console.log('store.pledges.mutations setCurrentPledge()', data);
  state.currentPledge = data;
}
