import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// eslint-disable-next-line no-unused-vars
import $ from 'jquery';

const app = createApp(App);

// app.config.productionTip = false;

// Import our Stylesheets
import './styles/index.scss';

// make router instance available in store
store.$router = router;

// Import any Plugins we want globally available in the application
import axios from './plugins/axios';
import popperjs from './plugins/popper';
import bootstrap from './plugins/bootstrap';
import errorProcessor from './plugins/errorProcessor';
import fontAwesome from './plugins/font-awesome';
// import { makeServer } from './plugins/server';
import permissions from './plugins/permissions';
import Select2 from './plugins/select2';
import vCalendar from './plugins/v-calendar';
import Error from './plugins/error';

// Run Plugin functions
axios({ store, app });
popperjs({ app });
bootstrap({ app });
errorProcessor({ app });
fontAwesome({ app });
permissions({ store, app });
Select2({ app });
vCalendar({ app });
Error({ app });
// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }

app.use(store);
app.use(router);

router.isReady().then(() => {
  app.mount('#app');
});
