export function getAll(state) {
  // console.log('store.donors.getters.getAll()', state.donors);
  return state.donors.data;
}

export function getAllMeta(state) {
  // console.log('store.donors.getters.getAll()', state.donors);
  return state.donors.meta;
}

export function getCurrentDonor(state) {
  // console.log('store.donors.getters.getCurrentDonor()', state);
  return state.currentDonor;
}
