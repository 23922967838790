export function setPromotions(state, data) {
  // console.log('store.promotions.mutations setPromotions()', data);
  state.promotions.data = data.data ?? [];
  state.promotions.meta = data.meta ?? {};
}

export function setCurrentPromotion(state, data) {
  // console.log('store.promotions.mutations setCurrentPromotion()', data);
  state.currentPromotion = data;
}

export function setCurrentPromotions(state, data) {
  // console.log('store.promotions.mutations setCurrentPromotions()', data);
  state.currentPromotions = data;
}
