export function getAll(state) {
  // console.log('store.pledges.getters.getAll()', state.pledges);
  return state.pledges.data;
}

export function getAllMeta(state) {
  // console.log('store.pledges.getters.getAll()', state.pledges);
  return state.pledges.meta;
}

export function getCurrentPledge(state) {
  // console.log('store.pledges.getters.getCurrentPledge()', state);
  return state.currentPledge;
}
