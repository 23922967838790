export function setIntentions(state, data) {
  // console.log('store.intentions.mutations setIntentions()', data);
  state.intentions.data = data.data ?? [];
  state.intentions.meta = data.meta ?? {};
}

export function setCurrentIntention(state, data) {
  // console.log('store.intentions.mutations setCurrentIntention()', data);
  state.currentIntention = data;
}
