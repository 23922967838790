import { axiosInstance as axios, queryParams } from '../../plugins/axios';

export async function getAll(context, payload) {
  const { params } = payload;
  // console.log('store.roles.actions.getAll() :params', params);
  const config = {
    method: 'get',
    url: `roles${queryParams(params)}`,
  };

  // console.info('store.roles.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.roles.actions.getAll() then', response);
      context.commit('setRoles', response.data);

      return response;
    })
    .catch((error) => {
      console.error('store.roles.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function get(context, payload) {
  console.log('store.roles.actions.get() :payload', payload);
  const { id, params } = payload;
  const config = {
    method: 'get',
    url: `roles/${id}${queryParams(params)}`,
  };

  // console.info('store.roles.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.roles.actions.get() then', response);
      context.commit('setCurrentRole', response.data.data);
      if (window.location.href.indexOf('roles') !== -1) {
        const title = response.data.data.name;
        context.commit('ui/setPageTitle', title, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.roles.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  // console.log('store.roles.actions.create() :payload', { payload });
  const { formData } = payload;
  const config = {
    method: 'post',
    url: 'roles',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.roles.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.roles.actions.create() then', response);
      context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.roles.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, payload) {
  // console.log('store.roles.actions.update() :payload', { payload });
  const { formData, options } = payload;
  const { method, id } = options;
  const config = {
    method,
    url: `roles/${id}`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.roles.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.roles.actions.update() then', response);
      context.dispatch('get', { id });

      return response;
    })
    .catch((error) => {
      console.error('store.roles.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  // console.log('store.roles.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `roles/${id}`,
  };

  // console.info('store.roles.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.roles.actions.destroy() then', response);
      context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.roles.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
