import axios from 'axios';
import * as _ from 'lodash';

const queryParams = (params) => {
  params = params || {};
  const query = Object.keys(params).length
    ? Object.keys(params)
        .map((key) => {
          return key + '=' + params[key];
        })
        .join('&')
    : false;

  return query ? `?${query}` : '';
};

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: process.env.NODE_ENV.toLowerCase() === 'local' ? 60000 : 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// leave the export, even if you don't use it
export default ({ store, app }) => {
  // Check for JWT in state, or in localstorage
  const token = store.getters['auth/getToken'];
  // console.log('axios.js token', _.isEmpty(token), token);
  if (!_.isEmpty(token)) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  // Set the interceptor to handle JWT token refresh
  axiosInstance.interceptors.response.use(
    (response) => {
      // Return a successful response back to the calling service
      return response;
    },
    (error) => {
      // console.log('axios.js interceptors error.config', error.config);
      // For convenience, pull out StatusCode and Url here
      const statusCode = error.response.status;
      const url = error.config.url.split('/').pop();
      console.error('axios.js interceptors statusCode, url', statusCode, url);

      // Pass all non 401s back to the caller.
      if (statusCode !== 401) {
        // console.log('axios.js interceptors not 401');
        return Promise.reject(error);
      }

      // If one of these AuthRoutes returns a 401, this is expected (no token) and carry on as usual
      const authRoutes = ['authenticate', 'logout'];
      if (authRoutes.includes(url) && statusCode === 401) {
        console.log('axios.js interceptors is authRoutes and 401', statusCode, url);
        return Promise.reject(error);
      }

      // Logout user if token refresh didn't work or user is disabled
      if (url === 'refresh' || store.getters['auth/getRememberMe'] === false) {
        // console.log('axios.js interceptors is refresh or not remembered');
        store.dispatch('auth/logout').then(() => {
          console.info('axios.js interceptors is refresh after logout');
          store.$router.push({ name: 'login' });
        });

        return Promise.reject(error);
      }

      // Check if the sent Authorization header matches the existing one.
      // If it doesn't try resend
      const config = error.config;
      if (config.headers.Authorization !== axiosInstance.defaults.headers.common['Authorization']) {
        console.info('axios.js interceptors Authorization is outdated :config', config);
        // New request with new token
        config.headers['Authorization'] = axiosInstance.defaults.headers.common['Authorization'];

        return axiosInstance.request(config);
      }

      // nothing is working, have them login again
      store.dispatch('auth/logout').then(() => {
        console.info('axios.js interceptors end');
        store.$router.push({ name: 'login' });
      });

      return Promise.reject(error);
    }
  );

  // vueApp.prototype.$axios = axiosInstance;
  app.config.globalProperties.$axios = axiosInstance;
};

export { axiosInstance, queryParams };
