import { axiosInstance as axios, queryParams } from '../../plugins/axios';
// import * as _ from 'lodash';

export async function getAll(context, payload) {
  const { params, saveToStore } = payload;
  console.log('store.donors.actions.getAll()');
  const config = {
    method: 'get',
    url: `donors${queryParams(params)}`,
  };

  console.info('store.donors.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.donors.actions.getAll() then', response);
      if (saveToStore) {
        context.commit('setDonors', response.data);
      }

      return response;
    })
    .catch((error) => {
      console.error('store.donors.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function get(context, { id }) {
  console.log('store.donors.actions.get() :id', { id });
  const config = {
    method: 'get',
    url: `donors/${id}`,
  };

  console.info('store.donors.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.donors.actions.get() then', response);
      context.commit('setCurrentDonor', response.data.data);
      if (window.location.href.indexOf('donors') !== -1) {
        const title = response.data.data.display_as
          ? response.data.data.display_as
          : [response.data.data.first, response.data.data.last].join(' ');
        context.commit('ui/setPageTitle', title, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.donors.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  console.log('store.donors.actions.create() :payload', { payload });
  const config = {
    method: 'post',
    url: 'donors',
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  console.info('store.donors.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.donors.actions.create() then', response);
      context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.donors.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, { id, payload }) {
  console.log('store.donors.actions.update() :id :payload', { id, payload });
  const config = {
    method: 'put',
    url: `donors/${id}`,
    data: payload,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  console.info('store.donors.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.donors.actions.update() then', response);
      context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.donors.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  console.log('store.donors.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `donors/${id}`,
  };

  console.info('store.donors.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.donors.actions.destroy() then', response);
      context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.donors.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
