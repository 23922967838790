import * as _ from 'lodash';

export default ({ store, app }) => {
  const permissions = {
    computed: {
      canCreate() {
        return this.canByRole(['admin', 'developer']);
      },
      canEdit() {
        return this.canByRole(['admin', 'developer', 'editor']);
      },
      me() {
        return store.getters['users/getActiveUser'];
      },
      myPermissions() {
        return _.uniq(this.myRoles.flatMap((role) => role.permissions));
      },
      myRoles() {
        return this.me.roles || [];
      },
      permissions() {
        return store.getters['roles/getPermissions'];
      },
    },
    methods: {
      can(action, model, roles) {
        // If a role is passed, check against my roles
        if (roles) {
          return this.canByRole(roles);
        }

        // If no role, then check against my permissions
        return this.myPermissions.includes(`${model}.${action}`);
      },
      canByRole(roles) {
        let hasRole = false;
        if (Array.isArray(roles)) {
          hasRole =
            _.intersection(
              roles,
              this.myRoles.map((role) => role.name)
            ).length > 0;
        } else {
          hasRole = this.myRoles.includes(roles);
        }

        return hasRole;
      },
      cant(action, model, roles) {
        return !this.can(action, model, roles);
      },
      cantByRole(roles) {
        return !this.canByRole(roles);
      },
      hasRole(role) {
        // console.log('permissions.js methods hasRole() :role', role, this.myRoles);
        return this.myRoles.some((r) => r.name === role);
      },
    },
  };

  app.mixin(permissions);
};
