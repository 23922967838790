import { createStore } from 'vuex';
// Modules
import auth from './auth';
import campaigns from './campaigns';
import donations from './donations';
import donors from './donors';
import favoriteComments from './favorite-comments';
import giveaways from './giveaways';
import goals from './goals';
import intentions from './intentions';
import matches from './matches';
import pledges from './pledges';
import promotions from './promotions';
import roles from './roles';
import search from './search';
import settings from './settings';
import ui from './ui';
import users from './users';

const store = createStore({
  modules: {
    auth,
    campaigns,
    donations,
    donors,
    favoriteComments,
    giveaways,
    goals,
    intentions,
    matches,
    pledges,
    promotions,
    roles,
    search,
    settings,
    ui,
    users,
  },
});

export default store;
