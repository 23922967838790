export function getAll(state) {
  // console.log('store.roles.getters.getAll()', state.roles);
  return state.roles.data;
}

export function getAllMeta(state) {
  // console.log('store.roles.getters.getAllMeta()', state.roles);
  return state.roles.meta;
}

export function getPermissions(state, getters, rootState, rootGetters) {
  // console.log('store.roles.getters.getPermissions() :roles', getters.getAllp);
  const permissions = {};
  const activeUser = rootGetters['users/getActiveUser'];
  const roles = getters.getAll;

  if (activeUser) {
    activeUser.roles.forEach((activeUserRole) => {
      roles.forEach((role) => {
        permissions[role.name] = activeUserRole.name === role.name;
      });
    });
  }

  // console.log('store.roles.getters.getPermissions() :permissions', permissions);

  return permissions;
}

export function getRoleByName(state) {
  return (name) => {
    const roles = state.roles.data.filter((role) => {
      // console.log('store.roles.getters.getRoleByName() filter', role.name, role.name === name);
      return role.name === name;
    });

    // console.log('store.roles.getters.getRoleByName() :name', { name, roles });
    return roles.length > 0 ? roles[0] : Object.assign({});
  };
}

export function getRoleById(state) {
  return (id) => {
    const roles = state.roles.data.filter((role) => {
      // console.log('store.roles.getters.getRoleById() filter', role.id, role.id === Number(id));
      return role.id === Number(id);
    });

    // console.log('store.roles.getters.getRoleById() :id', { id, roles });
    return roles.length > 0 ? roles[0] : Object.assign({});
  };
}
