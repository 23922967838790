export default {
  campaigns: {
    data: [],
    meta: {
      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 0,
        total_pages: 0,
        links: {},
      },
    },
  },
  currentCampaign: false,
  currentCampaigns: false,
  isLoading: false,
};
