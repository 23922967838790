export function getAll(state) {
  // console.log('store.favorite-comments.getters.getAll()', state.pledges);
  return state.favoriteComments.data;
}

export function getAllMeta(state) {
  // console.log('store.favorite-comments.getters.getAll()', state.pledges);
  return state.favoriteComments.meta;
}

export function isFavorite(state) {
  return (donation_id) => state.favoriteComments.data.find((fc) => fc.donation_id === donation_id);
}
