import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBullhorn,
  faChevronDown,
  faChevronUp,
  faCheckCircle,
  faClipboardList,
  faCompress,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faEquals,
  faExclamationCircle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFrown,
  faFrownOpen,
  faGift,
  faGifts,
  faGlobe,
  faHandshake,
  faHandshakeAlt,
  faHeart,
  faImage,
  faMap,
  faMapMarkedAlt,
  faMapPin,
  faSms,
  faMobile,
  faMoneyBill,
  faMoneyBillAlt,
  faMinusCircle,
  faNotEqual,
  faPhone,
  faPhoneSquare,
  faPlay,
  faPlusCircle,
  faRedo,
  faSave,
  faSlash,
  faStopwatch,
  faSync,
  faSyncAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrash,
  faTrashRestore,
  faUpload,
  faUserCircle,
  faUserTag,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default ({ app }) => {
  // Add all the icons used. We are being explicit so as not to bloat the final bundle
  library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBullhorn,
    faChevronDown,
    faChevronUp,
    faCheckCircle,
    faClipboardList,
    faCompress,
    faDollarSign,
    faDownload,
    faEdit,
    faEnvelope,
    faEquals,
    faExclamationCircle,
    faExpand,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFrown,
    faFrownOpen,
    faGift,
    faGifts,
    faGlobe,
    faHandshake,
    faHandshakeAlt,
    faHeart,
    faImage,
    faMap,
    faMapMarkedAlt,
    faMapPin,
    faSms,
    faMobile,
    faMoneyBill,
    faMoneyBillAlt,
    faMinusCircle,
    faNotEqual,
    faPhone,
    faPhoneSquare,
    faPlay,
    faPlusCircle,
    faRedo,
    faSave,
    faSlash,
    faStopwatch,
    faSync,
    faSyncAlt,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTrash,
    faTrashRestore,
    faUpload,
    faUserCircle,
    faUserTag,
    faWindowClose
  );
  // Add the Font-Awesome Vue Component
  app.component('font-awesome-icon', FontAwesomeIcon);
};
