import { axiosInstance as axios, queryParams } from '../../plugins/axios';

export async function getAll(context, payload) {
  const { params } = payload;
  // console.log('store.promotions.actions.getAll()');
  const config = {
    method: 'get',
    url: `promotions${queryParams(params)}`,
  };

  // console.log('store.promotions.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.promotions.actions.getAll() then', response);
      context.commit('setPromotions', response.data);

      return response;
    })
    .catch((error) => {
      console.error('store.promotions.actions.getAll() error', error);
      // #TODO Handle general network errors
      return error;
    });
}

export async function get(context, payload) {
  // console.log('store.promotions.actions.get() :payload', payload);
  const { id, params } = payload;
  const config = {
    method: 'get',
    url: `promotions/${id}${queryParams(params)}`,
  };

  // console.info('store.promotions.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.promotions.actions.get() then', response);
      context.commit('setCurrentPromotion', response.data.data);
      /*if (window.location.href.indexOf('promotions') !== -1) {
        context.commit('ui/setPageTitle', response.data.data.title, { root: true });
      }*/

      return response;
    })
    .catch((error) => {
      console.error('store.promotions.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getIntentions(context, payload) {
  const { id, params } = payload;
  // console.log('store.promotions.actions.getIntentions() :id', { id });
  const config = {
    method: 'get',
    url: `promotions/${id}/intentions${queryParams(params)}`,
  };

  // console.info('store.promotions.actions.getIntentions() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.promotions.actions.getIntentions() then', response);
      context.commit('intentions/setIntentions', response.data, { root: true });

      return response;
    })
    .catch((error) => {
      console.error('store.promotions.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  // console.log('store.promotions.actions.create() :payload', payload);
  const { formData, params } = payload;
  const config = {
    method: 'post',
    url: `promotions${queryParams(params)}`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.promotions.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.promotions.actions.create() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.promotions.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, payload) {
  // console.log('store.promotions.actions.update() :payload', payload);
  const { formData, options, params } = payload;
  const { method, id } = options;
  const config = {
    method,
    url: `promotions/${id}${queryParams(params)}`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.promotions.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.promotions.actions.update() then', response);
      context.dispatch('get', { id, params: { with: 'campaign;statistics;intentions' } });

      return response;
    })
    .catch((error) => {
      console.error('store.promotions.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  // console.log('store.promotions.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `promotions/${id}`,
  };

  // console.info('store.promotions.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      console.log('store.promotions.actions.destroy() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.promotions.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getCurrent(context, params) {
  params = params || {};
  // console.log('store.promotions.actions.getCurrent() params', params);
  const query = Object.keys(params).length
    ? Object.keys(params)
        .map((key) => {
          return key + '=' + params[key];
        })
        .join('&')
    : '';
  let url = 'promotions/now';
  if (query) {
    url = `promotions/now?${query}`;
  }
  const config = {
    method: 'get',
    url,
  };

  // console.info('store.promotions.actions.getCurrent() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.promotions.actions.getCurrent() then', response);
      context.commit('setCurrentPromotions', response.data.data);
      /*if (window.location.href.indexOf('promotions') !== -1) {
        context.commit('ui/setPageTitle', response.data.data.title, { root: true });
      }*/

      return response;
    })
    .catch((error) => {
      console.error('store.promotions.actions.getCurrent() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
