import dayjs from 'dayjs';

export function loggedIn(state, getters) {
  const loggedIn = state.token !== null && !getters.isTokenExpired;
  // console.log('store.auth.getters.loggedIn :loggedIn', loggedIn);
  return loggedIn;
}

export function getRememberMe(state) {
  // console.log('store.auth.getters.getRememberMe token', typeof state.rememberMe);
  // const localRememberMe = localStorage.getItem('remember')
  //   ? localStorage.getItem('remember').toLowerCase() === 'true'
  //   : false;

  return state.rememberMe; // ? state.rememberMe : localRememberMe;
}

export function getToken(state) {
  // console.log('store.auth.getters.getToken token', typeof state.token);
  // const localToken = localStorage.getItem('access_token')
  //   ? localStorage.getItem('access_token').split('|')[0]
  //   : null;

  return state.token; // ? state.token : localToken;
}

export function getExpires(state) {
  // console.log('store.auth.getters.getExpires expires', state.expires);
  return state.expires;
}

export function isTokenExpired(state) {
  // console.log('store.auth.getters.isTokenExpired', state.expires);
  return state.expires === null || state.expires.isBefore(dayjs());
}
