export function pageTitle(state) {
  return state.pageTitle;
}

export function isMenuOpen(state) {
  return state.menuOpen;
}

export function isDashboardRefresh(state) {
  return state.dashboardRefresh;
}

export function dashboardRefreshInterval(state) {
  return state.dashboardRefreshInterval;
}
