import { axiosInstance as axios, queryParams } from '../../plugins/axios';

export async function getAll(context, payload) {
  const { params } = payload;
  // console.log('store.pledges.actions.getAll() :params :payload', { params, payload });
  const config = {
    method: 'get',
    url: `pledges${queryParams(params)}`,
  };

  // console.info('store.pledges.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.pledges.actions.getAll() then', response);
      context.commit('setPledges', response.data);

      return response;
    })
    .catch((error) => {
      console.error('store.pledges.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function get(context, payload) {
  // console.log('store.pledges.actions.get() :payload', payload);
  const { id, params } = payload;
  const config = {
    method: 'get',
    url: `pledges/${id}${queryParams(params)}`,
  };

  // console.info('store.pledges.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.pledges.actions.get() then', response);
      context.commit('setCurrentPledge', response.data.data);
      if (window.location.href.indexOf('pledges') !== -1) {
        const title = response.data.data.display_as
          ? response.data.data.display_as
          : [response.data.data.first, response.data.data.last].join(' ');
        context.commit('ui/setPageTitle', title, { root: true });
      }

      return response;
    })
    .catch((error) => {
      console.error('store.pledges.actions.get() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  // console.log('store.pledges.actions.create() :payload', { payload });
  const { formData } = payload;
  const config = {
    method: 'post',
    url: 'pledges',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.pledges.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.pledges.actions.create() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.pledges.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, payload) {
  // console.log('store.pledges.actions.update() :payload', { payload });
  const { formData, options } = payload;
  const { method, id } = options;
  const config = {
    method,
    url: `pledges/${id}`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.pledges.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.pledges.actions.update() then', response);
      context.dispatch('get', { id });

      return response;
    })
    .catch((error) => {
      console.error('store.pledges.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  // console.log('store.pledges.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `pledges/${id}`,
  };

  // console.info('store.pledges.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.pledges.actions.destroy() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.pledges.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function getCoordinates(context, address) {
  // console.log('store.pledges.actions.getCoordinates() :address', address);
  const key = process.env.VUE_APP_GMAPS_API_KEY || '';
  const config = {
    method: 'get',
    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`,
  };

  console.info('store.pledges.actions.getCoordinates() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.pledges.actions.getCoordinates() then', response);
      const { data } = response;
      const { results } = data;

      return results.shift();
    })
    .catch((error) => {
      console.error('store.pledges.actions.getCoordinates() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
