export function setDonations(state, data) {
  // console.log('store.donations.mutations setDonations()', data);
  state.donations.data = data.data ?? [];
  state.donations.meta = data.meta ?? {};
}

export function setCurrentDonation(state, data) {
  // console.log('store.donations.mutations setCurrentDonation()', data);
  state.currentDonation = data;
}
