export function getAll(state) {
  // console.log('store.donations.getters.getAll()', state.donations);
  return state.donations.data;
}

export function getAllMeta(state) {
  // console.log('store.donations.getters.getAll()', state.donations);
  return state.donations.meta;
}

export function getCurrentDonation(state) {
  // console.log('store.donations.getters.getCurrentDonation()', state);
  return state.currentDonation;
}
