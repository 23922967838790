import { axiosInstance as axios } from '../../plugins/axios';
import * as _ from 'lodash';

export async function getAll(context, params) {
  // console.log('store.intentions.actions.getAll()', params);
  let url = 'intentions';
  if (Object.keys(params).length) {
    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    url = `intentions?${query}`;
  }

  const config = {
    method: 'get',
    url,
  };

  // console.info('store.intentions.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.intentions.actions.getAll() then', response);
      context.commit('setIntentions', response.data);

      return response;
    })
    .catch((error) => {
      console.error('store.intentions.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function get(context, { id }) {
  // console.log('store.intentions.actions.get() :id', { id });
  const config = {
    method: 'get',
    url: `intentions/${id}`,
  };

  // console.info('store.intentions.actions.get() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.intentions.actions.get() then', response);
      context.commit('setCurrentIntention', response.data.data);
      /*if (window.location.href.indexOf('intentions') !== -1) {
        const title = response.data.data.display_as
          ? response.data.data.display_as
          : [response.data.data.first, response.data.data.last].join(' ');
        context.commit('ui/setPageTitle', title, { root: true });
      }*/

      return response;
    })
    .catch((error) => {
      console.error('store.intentions.actions.get() error', error);
      // #TODO Handle general network errors
      return error;
    });
}

export async function create(context, payload) {
  // console.log('store.intentions.actions.create() :payload', payload);
  const { formData } = payload;
  const config = {
    method: 'post',
    url: 'intentions',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.intentions.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.intentions.actions.create() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.intentions.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function update(context, payload) {
  // console.log('store.intentions.actions.update() :id :payload', payload);
  const { formData, options } = payload;
  const config = {
    method: options.method,
    url: `intentions/${options.id}`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // console.info('store.intentions.actions.update() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.intentions.actions.update() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.intentions.actions.update() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  // console.log('store.intentions.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `intentions/${id}`,
  };

  // console.info('store.intentions.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.intentions.actions.destroy() then', response);
      // context.dispatch('getAll');

      return response;
    })
    .catch((error) => {
      console.error('store.intentions.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
