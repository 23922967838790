export function getAll(state) {
  // console.log('store.promotions.getters.getAll()', state.promotions);
  return state.promotions.data;
}

export function getAllMeta(state) {
  // console.log('store.promotions.getters.getAll()', state.promotions);
  return state.promotions.meta;
}

export function getCurrentPromotion(state) {
  // console.log('store.promotions.getters.getCurrentPromotion()', state);
  return state.currentPromotion;
}

export function getCurrentPromotions(state) {
  // console.log('store.promotions.getters.getCurrentPromotions()', state);
  return state.currentPromotions;
}
