export function getAll(state) {
  // console.log('store.campaigns.getters.getAll()', state.campaigns);
  return state.campaigns.data;
}

export function getAllMeta(state) {
  // console.log('store.campaigns.getters.getAll()', state.campaigns);
  return state.campaigns.meta;
}

export function getCurrentCampaign(state) {
  // console.log('store.campaigns.getters.getCurrentCampaign()', state);
  return state.currentCampaign;
}

export function getCurrentCampaigns(state) {
  // console.log('store.campaigns.getters.getCurrentCampaigns()', state);
  return state.currentCampaigns;
}

export function getCampaignById(state, { id }) {
  return state.campaigns.data
    .filter((campaign) => {
      return campaign.campaign_id === id;
    })
    .shift();
}
