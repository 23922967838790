import { axiosInstance as axios, queryParams } from '../../plugins/axios';

export async function getAll(context, payload) {
  const { params } = payload;
  // console.log('store.favorite-comments.actions.getAll() :params :payload', { params, payload });
  const config = {
    method: 'get',
    url: `favorite-comments${queryParams(params)}`,
  };

  // console.info('store.favorite-comments.actions.getAll() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.favorite-comments.actions.getAll() then', response);
      context.commit('setFavoriteComments', response.data);

      return response;
    })
    .catch((error) => {
      console.error('store.favorite-comments.actions.getAll() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function create(context, payload) {
  console.log('store.favorite-comments.actions.create() :payload', { payload });
  const config = {
    method: 'post',
    url: 'favorite-comments',
    data: payload,
  };

  // console.info('store.favorite-comments.actions.create() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.favorite-comments.actions.create() then', response);
      context.dispatch('getAll', {});

      return response;
    })
    .catch((error) => {
      console.error('store.favorite-comments.actions.create() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}

export async function destroy(context, { id }) {
  // console.log('store.favorite-comments.actions.destroy() :id', id);
  const config = {
    method: 'delete',
    url: `favorite-comments/${id}`,
  };

  // console.info('store.favorite-comments.actions.destroy() before', { config });

  return axios
    .request(config)
    .then((response) => {
      // console.log('store.favorite-comments.actions.destroy() then', response);
      context.dispatch('getAll', {});

      return response;
    })
    .catch((error) => {
      console.error('store.favorite-comments.actions.destroy() error', error);
      // #TODO Handle general network errors
      throw error;
    });
}
